<template>
    <div class="card bs-5 mb-5 container">
        <div class="fl-te-c pb-3">
            <h4 v-html="'PROGRAM'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.programAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn icon="fa fa-eye" text="View" color="info" size="xs"
                         @click="$router.push({path: '/programs/' + rowData.id + '/details/'})" />
                    <edit-btn @click="setEdit(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Program" ref="programAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddProgram @success="formSuccess"></AddProgram>
        </modal>

        <modal title="Edit Program" ref="programEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditProgram :initial-data="editingItem" @success="formSuccess"></EditProgram>
        </modal>

        <delete-modal ref="programDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Program <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import AddProgram from '@/views/admin/programs/AddProgram';
import EditProgram from '@/views/admin/programs/EditProgram';

export default {
    name : 'Program',

    components : { AddProgram, EditProgram },

    data () {
        return {
            listUrl      : urls.programs.program.list,
            deleteUrl    : urls.programs.program.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'name',
                    sortField  : 'name',
                    title      : 'Name',
                    titleClass : ''
                },
                {
                    name       : 'content',
                    sortField  : 'content',
                    title      : 'Content',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },

    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.programAddModal.close();
            refs.programEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.programEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.programDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Program..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.programDeleteModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
